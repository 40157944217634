
@font-face {
    font-family: DaMiOne;
    src: url('../fonts/DaMiOne-Regular.ttf');
    src: url('../fonts/DaMiOne-Regular.otf');

}
@font-face {
    font-family: Gropled;
    src: url('../fonts/Gropled-Bold.otf');
    src: url('../fonts/Gropled-Bold.ttf');
}
@font-face {
    font-family: Evolventa;
    src: url('../fonts/Evolventa-Regular.otf');
    src: url('../fonts/Evolventa-Regular.ttf');
}

@import '../variables/Variables.module';


*{
    margin: 0;
    padding: 0;
    font-family: $font-text;
}
body{
    width: 100%;
}
h1, h2, h3, h4, h5, h6{
    font-family: $font-title;
    color: $color-accent;
    margin: 0;
    padding: 0;
}
a, ol, ul, li{
    margin: 0;
    padding: 0;
    text-decoration: none;
    border: none;
    outline: none;
    list-style: none;
    color: $color-accent;
}
button, input{
    width: auto;
    border: none;
    outline: none;
    background: transparent;
}
