@import '../../style/variables/Variables.module';

.base{

h5, h1, ol, ul, li{
    color: $color-black;
    font-family: $font-desc;

}
h1{
    text-transform: uppercase;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 1rem;
}
h5{
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    font-weight: 800;
}
ol{
    list-style-type: none; 
	counter-reset: num;
	margin: 0 0 0 35px;
	padding: 15px 0 5px 0;
	font-size: 18px;
    li{
        list-style-type: decimal;
    }
}
ul{
    
    padding: 0;
    margin: 0;
    padding: 15px;
    li{
        list-style-type: square;
    }
}


}
