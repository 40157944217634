@import '../../style/variables/Variables.module';

.modal{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    width: 100%;
    height: 100vh;
    transition: opacity .3s;
    &__body{
        position: relative;
        width: 360px;
        background: $color-grey-light;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        transform: translateY(-50px);
        transition: all .3s;
        &__mix{
            width: 330px;
        }
    }
    &__close{
        position: absolute;
        top: 5px;
        right: 2%;
        margin-bottom: 20px;
        font-size: 40px;
        cursor: pointer;
    }
}
.modal__entering .modal__exited {
    opacity: 0; }
  .modal__entering .modal__content .modal__exited .modal__content {
    transform: translateY(-50px); }