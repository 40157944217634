@import '../../style/variables/Variables.module';

.page{
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $color-base;
    overflow-y: hidden;
    &__title{
        padding-top: 10%;
        transform: rotate(-45deg);
        h1{
            padding-top: 10rem;
            font-size: 80px;
        }
        h2{
            font-size: 30px;
            font-family: $font-text;
        }
    }

    &__ufo{
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        top: 3rem;
        bottom: 0;
        width: 250px;
        height: 250px;
        // 
        &__roof{
            position: absolute;
            width: 70px;
            height: 70px;
            top: 1rem;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            text-align: center;
            border-radius: 50%;
            background: rgba(255, 255, 0, 0.8);
        }
        &__bottom{
            z-index: 10;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60%;
            width: 100%;
            background: $color-black;
            clip-path: ellipse(50% 16.48% at 50% 50%);
        }
        &__circle{
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: rgba(255, 255, 0, 1);
            margin-left: 10px;
            margin-right: 10px;
        }
        &__ray {
            position: absolute;
            top: 30%;
            width: 100%;
            height: calc(100vh - 350px);
            background:  rgba(255, 255, 0, .4);
            clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        }
    }
    &__home{
        position: absolute;
        bottom: 0;
        width: 300px;
        height: 300px;
        display: flex;
        &__link{
            background: $color-add;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 300px;
            height: 200px;
            text-align: center;
            clip-path: polygon(50% 0%,70% 15%,70% 2%,90% 2%,90% 30%,100% 40%,100% 100%,65% 100%,64.86% 51.24%,49.28% 51.24%,37.03% 62.25%,22.69% 61.80%,22.64% 81.70%,37.24% 82.20%,37.19% 62.05%,49.10% 51.51%,49.00% 100%,0% 100%,0% 40%);            p{
            color: $color-black;
            font-family: $font-title;
            font-size: 20px;
            }
        }
    }
}

@for $i from 1 through 7 {
    .box#{$i} {
        animation: flick#{$i};
        animation-duration: 1s;
        animation-timing-function: linear;
        // animation-delay: #{$i}s;
        animation-iteration-count: infinite;
        animation-direction: normal;
        animation-fill-mode: none;
    }

    @keyframes flick#{$i} {
        0% {
            opacity: 0;
        }
        #{$i * 10}% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
}
