@import '../../style/variables/Variables.module';

.body{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    h2, h3{
        color: $color-base;
        font-family: $font-text;
    }
    h2{
        font-size: 38px;
        letter-spacing: 4px;
    }
    h3{
        font-size: 14px;
        letter-spacing: 2px;
        padding-bottom: 2rem;
    }
    &__form{
        width: 100%;
        &__block{
            width: 100%;
            margin-top: 1rem;
            margin-bottom: 1rem;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 2rem;
            label{
                position: absolute;
                bottom: 2px;
                left: 10px;
                color: $color-base;
                pointer-events: none;
                transition: transform 0.2s ease-out, font-size 0.2s ease-out;
            }
            input {
                width: 100%;
                height: 45px;
                border: 1px solid rgba(240, 205, 153, 0.8);
                background: rgba(102, 204, 255, .3);
                font-size: 17px;
                color: $color-base;
                padding-left: 7px;
            }
            &:nth-child(4) {
                padding-top: 0;
                margin-top: 0;
                margin-bottom: 0; 
                align-items: flex-end;
            }
            p, a {
                font-size: 8px;
                text-align: center;
                color: $color-black;
                padding-right: 8px;
                padding-bottom: 8px;
                margin: 0; 
            }
           
        }
        button {
            margin-top: 2rem;
            margin-bottom: 1rem;
            width: 100%;
            height: 55px;
            background-color: $color-base;
            border: 1px solid $color-base;
            font-size: 18px;
            text-transform: uppercase;
            color: $color-accent;
            letter-spacing: 2px;
            padding-left: 7px;
            font-weight: 900;
            font-family: $font-title;
            transition: all .3s ease; 
        }
    }
}
.active {
    transform: translateY(-40px);
    font-size: 0.8em;
    padding-bottom: 8px; 
}
  
  .successMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 350px;
    width: 100%; }
    .successMessage__circle svg {
      font-size: 70px;
      font-weight: 200;
      color: $color-base;
      animation-name: fade-out-tr;
      animation-duration: 2s;
      animation-timing-function: linear;
      animation-delay: 0s;
      animation-iteration-count: 1;
      animation-direction: normal;
      animation-fill-mode: none;  
        opacity: 0;
    }
    .successMessage p {
        padding-bottom: 1rem;
        padding-right: 2rem;
        padding-left: 2rem;
        color: $color-base;
        font-size: 16px;
        animation-name: fade-in;
	    animation-duration: 2s;
		animation-timing-function: linear;
		animation-delay: 0s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: none; }
  
@keyframes fade-out-tr {	
    0% {
        transform:translateX(0) translateY(0);
        opacity:1;
        }
    100% {
        transform:translateX(50px) translateY(-50px);
        opacity:0;
    }
}
  @keyframes fade-in {
    0% {
      opacity: 0; }
    80% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  
