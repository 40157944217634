

$font-title: DaMiOne;
$font-text: Gropled;
$font-desc: Evolventa;

$color-base: rgb(0, 51, 102);
$color-add: rgb(102, 204, 255);
$color-accent: rgb(255, 153, 0);
$color-white: rgb(255, 255, 255);
$color-grey: rgb(51, 51, 51);
$color-black: rgb(0, 0, 0);
$color-grey-light: rgba(233, 233, 233, 1);