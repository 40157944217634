@import '../../style/variables/Variables.module';

.scrollToTop {
  z-index: 12;
  position: fixed;
  bottom: 20px;
  left: 20px;
  cursor: pointer;
  font-size: 55px;
  color: $color-accent; 
  opacity: 1;
  animation: flicker 2s linear infinite;
}

@keyframes flicker {
  0%{
    opacity: 1;
    transform: scale(0.95);
  }
  50%{
    opacity: .9;
    transform: scale(1.05);
    filter:invert(100%)
  }
  100%{
    opacity: 1;
    transform: scale(0.95);
  }
}
