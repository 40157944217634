@import '../../style/variables/Variables.module';

.header{
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    top: 3rem;
    left: 20px;
    animation-name: fade-in-top;
	animation-duration: 6s;
	animation-timing-function: linear;
	animation-delay: 0s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-fill-mode: none;
    &__nav{
        &__pc{
            display: block;
        }
        &__mobil{
            display: none;
        }
    }
}
@media (max-width: 1200px) {
    .header{
        position: absolute;
        bottom: 5px;
        right: 10px;
        flex-direction: row-reverse;
        justify-content: end;
        align-items: end;
        &__nav{
            &__pc{
                display: none;
            }
            &__mobil{
                display: block;
                z-index: 10;
                color: $color-accent;
                font-size: 35px;
                cursor: pointer;
            }
        }
    }

}

@keyframes fade-in-top {
			
    0% {
        transform:translateY(-50px);
        opacity:0;
    }
    90%{
        transform:translateY(-50px);
        opacity:0;
    }
    100% {
        transform:translateY(0);
        opacity:1;
    }
}
