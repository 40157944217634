@import '../../style/variables/Variables.module';

.box{
    position: relative;
    width: 450px;
    margin-left: 2rem;
    padding-top: 2rem;
    padding-left: 2rem;

    &__border{
        width: 350px;
        height: 150px;
        margin-left: 3rem;
        bottom: 0;
        right: 0;
        border: 5px solid rgba(255, 153, 0, .5);
    }
    &__text{
        position: absolute;
        text-align: right;
        top: 3.5rem;
        left: 2rem;
        // filter: contrast(150%);
        h2, h3{
            font-size: 33px;
            font-weight: 900;
            color: $color-accent;
            letter-spacing: 3px;
        }
        h3{
            font-size: 20px;
            padding-bottom: 6px;
        }
        
    }
}