@import '../../style/variables/Variables.module';

.partners{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    background: $color-grey-light;
    // padding-top: 3rem;
    &__top{
        width: 100%;
        min-height: 40vh;
        position: relative;
        background-repeat: no-repeat;
        background-position:  bottom left;
        background-size: cover;
        background-image: url(../../../../public/image/partners/001.jpg);
        &__logo{
            padding-right: 3rem;
        }
    }
    h4{
        color: $color-base;
        font-family: $font-text;
        padding-bottom: 1rem;
    }
    &__block{
        padding-top: 3rem;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
    &__logo{
        width: 200px;
        text-align: center;
        margin-right: 2rem;
        margin-left: 2rem;
        filter: grayscale(100%);
        transition: all .5s ease;
        img{
            width: 180px;
        }
        h5, h6, p{
            font-family: $font-desc;
            padding-top: 1rem;
            color: $color-base;
        }
        h6{
            padding-top: 4px;
            font-size: 14px;
        }
        p{
            padding-top: 0.5rem;
            font-size: 10px;
        }
        &:hover{
            filter: grayscale(0);
        }
    }
}