@import '../../style/variables/Variables.module';

.footer{
    width: 100%;
    min-height: 500px;
    position: relative;
    background: $color-base;
    display: flex;
    flex-direction: row;
    &__left{
        position: relative;
        height: 100px;
        width: 50%;
        &__square{
            position: absolute;
            margin-top: 0.5rem;
            margin-right: auto;
            left: 3rem;
            top: 3rem;
            text-align: center;
            width: calc(100% - 3rem);
            height: 300px;
            border: 10px solid rgba(255, 153, 0, .3);
            &:nth-child(2){
                top: 6rem;
                left: 6rem;
            }
        }
    }
    &__right{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-top: 3rem;
        h6{
            padding-right: 3rem;
            font-size: 14px;
            font-family: $font-text;
            padding-bottom: 1rem;
        }
        &__bottom{
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;
            &__left{
                width: 50%;
                height: 100%;
                background: red;
            }
            &__right{
                width: 50%;
                height: 100%;
                text-align: right;
                padding-right: 3rem;
                &__link{
                    display: flex;
                    flex-direction: column;
                    padding-top: 3rem;
                    font-size: 11px;
                    a{
                        padding-top: 0.5rem;
                    }
                }
            }
        }
    }
}
ul{
    padding-top: 3rem;
}

.link{
    display: inline-block;
    position: relative;
    padding-top: 1rem;

}

.hover {
    &:after {
        content:'';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: $color-accent;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.3s;
    }

    &:hover {
        &:after {
            transform-origin: bottom left;
            transform: scaleX(1);
        }
    }
}

@media (max-width: 1200px){
    .footer{
        flex-direction: column;
        &__left{
            width: 100%;
            &__square{
                width: 60%;
            }
        }
        &__right{
            width: 100%;
            h6{
                display: none;
            }
        }
    }
}