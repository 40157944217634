@import '../../style/variables/Variables.module';

.services{
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    min-height: 100vh;
    background: $color-grey-light;
    &__top{
        position: relative;
        width: 50%;
        min-height: 100%;
        background: $color-base;
        display: flex;
        justify-content: flex-end;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(../../../../public/image/services/001.jpg);
        &__logo{
            margin-right: 3rem;
        }
    }
    &__triangle{
        position: absolute;
        bottom: 0;
        right: -80px;
    }
    &__bottom{
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        flex: 1 1;
        &__text{
            padding-left: 2rem;
            padding-right: 2rem;
            padding-top: 1.5rem;
            padding-bottom: 2rem;
            font-size: 18px;
            letter-spacing: 2px;
            line-height: 28px;
            text-align: center;
            font-weight: 400;
            p, span{
                font-family: $font-desc;
            }
            span{
                font-weight: 800;
                font-size: 19px;
            }
            ul{
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
                text-align: right;
            }
            li{
                color: $color-black;
                font-family: $font-desc;
                padding-right: 2rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
        }
        &__list{
            display: flex;
            flex-direction: column;
            text-align: center;
            width: 100%;
            ul{
                display: flex;
                
                width: 100%;
            }
            li{
                display: flex;
                justify-content: center;
                align-items: center;
                flex:1 1 auto; 
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
                color: $color-black;
                text-transform: uppercase;
                text-align: center;
                border-bottom: 1px solid $color-black;
                border-right: 1px solid $color-black;
                cursor: pointer;
                font-family: $font-desc;
                font-size: 20px;
                transition: all .5s ease;
                &:hover{
                    background: rgba(0, 51, 102, .3);
                }
            }
            h3{
                padding: 2rem 2rem;
                font-size: 26px;
                font-family: $font-desc;
                color: $color-black;
            }
            
        }
    }
}

.selected{
    background: rgba(0, 51, 102, .3);
}
.modal_btn{
    width: 50%;
    min-width: 300px;
    min-height: 100px;
    border: 1px solid $color-add;
    padding: 5px 10px;
    margin-bottom: 1rem;
    transition: all .5s ease;
    background: rgba(102, 204, 255, 0.6);
    font-family: $font-desc;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 2px;
    &:hover{
        background: rgba(0, 51, 102, .3);
        border: 1px solid rgba(0, 51, 102, 1);
    }
}

@media (max-width: 1200px) {
    .services{
        flex-direction: column;
        &__top{
            width: 100%;
            min-height: 560px;
        }
        &__bottom{
            width: 100%;
            &__list{
                flex-direction: column;
                ul{
                    flex-direction: column;
                    li{
                        border-left: 1px solid $color-black;
                    }
                }
            }
        }
    }
}