@import '../../style/variables/Variables.module';

.contact{
    background: $color-grey-light;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    &__left{
        width: 50%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(../../../../public/image/contact/001.jpg);
    }
    &__right{
        width: 50%;
        &__bottom{
            text-align: right;
            padding-right: 3rem;
            padding-top: 3rem;
            padding-bottom: 3rem;
            font-size: 12px;
            &__text{
                margin-top: 3rem;
                position: relative;
                h5, h6{
                    color: $color-base;
                    font-weight: 400;
                    font-size: 14px;
                    padding-bottom: 0.5rem;
                }
                h6{
                    padding-top: 1rem;
                }
                p, a{
                    margin: 0;
                    padding: 0;
                    color: $color-base;
                    font-weight: 400;
                    font-size: 13px;
                }
            }
        }
    }
    &__map{
        width: 100%;
        height: 400px;
        position: relative;
        h3, h4{
            top: 1rem;
            right: 3rem;
            position: absolute;
            color: $color-base;
            font-weight: 500;
            font-size: 16px;
            font-family: $font-text;
        }
        h3{
            font-size: 32px;
            padding-bottom: 0.5rem;
            font-family: $font-title;
        }
        h4{
            top: 3rem;
        }
    }
}
.logo{
    position: absolute;
    top: 0;
    right: 0;
}

.square{
    display: block;
    position: absolute;
    bottom: 0;
    right: 8rem;
    z-index: 0;
}

@media (max-width: 1200px){
    .contact{
        flex-direction: column;
        &__left{
            width: 100%;
            height: 400px;
        }
        &__right{
            width: 100%;

        }
    }
    .square{
        display: none;
    }
}