@import '../../style/variables/Variables.module';

.sidebar{
    z-index: 11;
    position: absolute;
    top: 100px;
    &__svg{
        position: absolute;
        z-index: 12;
        right: 0;
        bottom: 0px;
        font-size: 55px;
        color: $color-accent;
        font-weight: 900;
    }
    &__nav{
        position: fixed;
        right: -10px;
        top: -182px;
        width: 250px;
        height: 150vh;
        background: rgba(10, 10, 10, 0.6);
        transition: all .4s ease;
        &__list{
            margin-top: 400px;
            display: flex;
            position: relative;
            flex-direction: column;
            
            
            &__icon{
                margin-right: 2rem;
                margin-left: 2rem;
                margin-top: 2rem;
            }
        }
        
    }
}
.active{
    opacity: 1;
    visibility: visible;
    display: block;
    animation-name: fade-in;
	animation-duration: 0.5s;
	animation-timing-function: linear;
	animation-delay: 0s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-fill-mode: none;
    
}

.deactive{
    opacity: 0;
    visibility: hidden;
}
.open{
    position: fixed;
    top: 160px;
    right: 19px;
    
}


@keyframes fade-in {
			
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}