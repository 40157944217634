@import '../../style/variables/Variables.module';

.main{
    display: flex;
    width: 100%;
    height: 100vh;
    &__left{
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-end;
        width: 50%;
        height: 100%;
        background: $color-grey-light;
    }
    &__right{
        position: relative;
        width: 50%;
        height: 100%;
        background: $color-base;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        a{
            padding-right: 3rem;
        }
        &__title{
            display: flex;
            flex-direction: column;
            align-items: center;
            &__logo{
                display: flex;
                flex-direction: column;
                font-size: 50px;
                width: 300px;
                white-space: nowrap;
                overflow: hidden;
                margin-top: 12px;
                border-right: 3px solid transparent;
                text-align: right;
                h2{
                    font-weight: 800;
                    letter-spacing: 3px;
                    margin: 0;
                    font-size: 55px;
                    text-align: right;
                    animation-name: text-flicker-in-glow;
		            animation-duration: 6s;
		            animation-timing-function: linear;
		            animation-iteration-count: 1;
		            animation-direction: normal;
		            animation-fill-mode: none;
                }
                p{
                    font-size: 12px;
                    letter-spacing: 3px;
                    color: $color-accent;
                    font-weight: 200;
                    animation-name: fade;
		            animation-duration: 7s;
		            animation-timing-function: linear;
		            animation-iteration-count: 1;
		            animation-direction: normal;
		            animation-fill-mode: none;
                    
                }
            }
            &__text{
                display: flex;
                height: 28px;
                margin-top: 2rem;
                width: 270px;
                white-space: nowrap;
                overflow: hidden;
                font-weight: 800;
                border-right: 6px solid transparent;
                animation: typing 2.5s steps(19), blink .5s step-end infinite alternate;
                text-align: right;
                h1{
                    padding-top: 6px;
                    padding-bottom: 6px;
                    font-family: $font-text;
                    letter-spacing: 2px;
                    font-size: 18px;
                }
            }
        }
    }
}

.circle{
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -250px;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: $color-grey-light;
    overflow: hidden;
    &__mini{
        width: 450px;
        height: 450px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        animation-name: fade;
        animation-duration: 1.5s;
        animation-timing-function: linear;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: none;
        background:linear-gradient(225deg, rgba(0, 51, 102, 1) 0%, rgba(102, 204, 255, 1) 80%);
    }
}
.logo{
    display: block;
    position: relative;
    z-index: 2;
    width: 350px;
    height: 350px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../../../public/image/bg/002.png);

}

@keyframes typing {
0%{width: 0;
}
75%{width: 0;}
}
@keyframes blink {
0%{border-color: transparent;}
50%{ border-color: $color-accent;}
100%{border-color: transparent;}
}
@keyframes text-flicker-in-glow {
			
    0% {
        opacity:0;
    }
    50%{
        opacity: 0;
    }
    55% {
        opacity:1;

    }
    56% {
        opacity:0;

    }
    56% {
        opacity:0;

    }
    57% {
        opacity:1;
    }
    60% {
        opacity:1;
    }
    61% {
        opacity:0;
    }
    65% {
        opacity:0;
    }
    66% {
        opacity:1;
    }
    75% {
        opacity:1;
    }
    76% {
        opacity:0;
    }
    77% {
        opacity:0;
    }
    78% {
        opacity:1;
    }
    85% {
        opacity:1;
    }
    86% {
        opacity:0;
    }
    87% {
        opacity:0;
    }
    88% {
        opacity:1;
    }
    100% {
        opacity:1;
        
    }
}
@keyframes fade {
    0% {
        filter:blur(4px);
        opacity:0;
    }
    70%{
        transform:scale(1.1);
        filter:blur(4px);
        opacity:0;
    }
    100% {
        transform:scale(1);
        filter:blur(0);
        opacity:1;
    }
}


@media (max-width: 1200px) {
    .main{
        flex-direction: column-reverse;
        height: auto;
        &__left{
            width: 100%;
            align-items: center;
        }
        &__right{
            width: 100%;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 3rem;
            height: 230px;
        }
    }
    .circle{
        position: relative;
        align-items: center;
        right: 0;
        margin-top: 2rem;
        margin-left: 0;
        margin-bottom: 2rem;
        width: 350px;
        height: 350px;
    }


}