@import '../../style/variables/Variables.module';


.form{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    padding-top: 2rem;
    background: $color-grey-light;
    &__bottom{
        width: 100%;
        height: 100%;
        padding-top: 0rem;
        padding-bottom: 1.5rem;
    }
}