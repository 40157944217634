@import '../../style/variables/Variables.module';

.block{
    background: transparent;
    padding-right: 3rem;
    padding-bottom: 2rem;
    h6{
        font-size: 16px;
        letter-spacing: 3px;
        font-family: $font-desc;
        font-weight: 700;
        filter: contrast(200%);


    }
    a{
        color: $color-accent;
        font-size: 16px;
        font-family: $font-desc;
        font-weight: 700;

    }
}