@import '../../style/variables/Variables.module';

.social {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-right: 3rem;

    a {
        padding: 0;
        margin: 0;
    }

    &__circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: transparent;
        border-radius: 50%;
        animation-name: fade-in-top;
	    animation-duration: 8s;
	    animation-timing-function: linear;
	    animation-delay: 0s;
	    animation-iteration-count: 1;
	    animation-direction: normal;
	    animation-fill-mode: none;
        transition: all .5s ease-in-out, background-color .5s ease-in-out;
        cursor: pointer;
        margin-right: 16px;
        font-size: 18px;
        color: $color-white;

        &:hover {
            transform: scale(1.1);
            font-size: 21px;
            color: $color-white;
        }

        &:nth-of-type(1) {
            &:hover {
                background: radial-gradient(circle farthest-side at center center, #34b7f1 27%, transparent 100%);
            }
        }
        &:nth-of-type(2) {
            &:hover {
                background: radial-gradient(circle farthest-side at center center, #25d366 70%, transparent 100%);
            }
        }
        &:nth-of-type(3) {
            &:hover {
                background: radial-gradient(circle farthest-side at center center, #27A7E7 70%, transparent 100%);
            }
        }
        &:nth-of-type(4) {
            &:hover {
                background: radial-gradient(circle farthest-side at center center, #c71610 70%, transparent 100%);
            }
        }
        &:nth-of-type(5) {
            &:hover {
                background: radial-gradient(circle farthest-side at center center, #4C75A3 70%, transparent 100%);
            }
        }
        &:nth-of-type(6) {
            &:hover {
                background: radial-gradient(circle farthest-side at center center, #FF0000 70%, transparent 100%);
            }
        }
    }
}

@media (max-width: 1200px) {
    .social{
        z-index: 10;
    }
}

@keyframes fade-in-top {
			
    0% {
        transform:translateY(-50px);
        opacity:0;
    }
    90%{
        transform:translateY(-30px);
        opacity:0;
    }
    100% {
        transform:translateY(0);
        opacity:1;
    }
}
