@import '../../style/variables/Variables.module';

.about{
    position: relative;
    display: flex;
    flex-direction: row;
    z-index: 2;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: $color-grey-light;
    &__left{
        position: relative;
        overflow: hidden;
        width: 50%;
        min-height: 100%;
        background-color: $color-base;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(../../../../public/image/about/001.jpg);
    }
    &__right{
        width: 50%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        padding-bottom: 2rem;
        &__triangle{
            position: absolute;
            bottom: 0;
            right: -80px;
            overflow: hidden;

        }
        &__img{
            position: absolute;
            width: 500px;
            height: 500px;
            bottom: 0;
            right: -80px;
            overflow: hidden;
            backdrop-filter: grayscale(20%);
        }
        &__block{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            flex: 1;
            width: 100%;
            padding-top: 2rem;
            padding-left: 4rem;
            &__icon{
                width: 100%;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                font-size: 45px;
                svg{
                    margin-right: 1rem;
                    margin-left: 1rem;
                    margin-top: 1rem;
                    color: $color-base;
                    filter: grayscale(50%) opacity(50%);
                    transition: all .5s ease;
                    &:hover{
                        filter: grayscale(0%) opacity(100%); 
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    &:first-child{
                        margin-left: 0;
                    }
                }
            }
        }

    }
}


.card{
    width: 100%;
    // display: flex;
    text-align: center;
    flex: 1;
    p{
        padding-bottom: 3rem;
        padding-top: 1rem;
        padding-left: 4rem;
        padding-right: 4rem;
        font-size: 18px;
        color: $color-black;
        font-weight: 500;
        letter-spacing: 2px;
        filter: contrast(200%)
    }
}

@media (max-width: 1200px){
    .about{
        height: 100%;
        flex-direction: column;
        &__left{
            width: 100%;
            min-height: 560px;
            text-align: center;
        }
        &__right{
            width: 100%;
            &__block{
                padding-top: 2rem;
                padding-left: 1rem;
                &__icon{
                    width: 100%;
                }
            }
        }
    }
    .card{
        width: 100%;
    }
}
