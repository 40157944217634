@import '../../style/variables/Variables.module';

.triangle{
    position: relative;
    overflow: hidden;
    width: 400px;
    height: 400px;
    background: transparent;
    &__one{
        position: absolute;
        top: 0;
        right: 100px;
        width: 0;
        height: 0;
        border-left: 100px solid transparent;
        border-right: 100px solid transparent;
        border-bottom: 200px solid rgba(255, 153, 0, .3);
    }
    &__two{
        position: absolute;
        right: 0px;
        bottom: 0px;
        width: 0;
        height: 0;
        border-left: 100px solid rgba(255, 153, 0, .3);
        border-right: 100px solid transparent;
        border-bottom: 200px solid rgba(255, 153, 0, .3);
    }
    &__there{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 0;
        border-left: 100px solid transparent;
        border-right: 100px solid rgba(255, 153, 0, .3);
        border-bottom: 200px solid rgba(255, 153, 0, .3);
    }
}