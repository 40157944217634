@import '../../style/variables/Variables.module';

.nav{
    margin-left: 2rem;
    margin-right: 2rem;
    &__list{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 5px;
        color: $color-accent;
        li{
            padding-bottom: 3px;
            padding-right: 8px;
            padding-left: 8px;
            letter-spacing: 1px;
            cursor: pointer;
            margin-bottom: 8px;
            margin-left: 8px;
            margin-right: 8px;
            
        }
    }
}

.count{
    position: relative;
    font-size: 20px;

}
.wrapper{
    position: relative;
    display: block;


}
.link{
    display: inline-block;
    position: relative;
    padding-top: 10px;

}

.hover {
    &:after {
        content:'';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: $color-accent;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.3s;
    }

    &:hover {
        &:after {
            transform-origin: bottom left;
            transform: scaleX(1);
        }
    }
}

@media (max-width: 1200px) {
    .nav{
    &__list{
        flex-direction: column;
    }
    }
}